<div class="top-header">
    <div class="container">
        <div class="row align-items-center text-center">
            <div class="col-lg-12 col-sm-6">
                <ul class="header-left-content">
                    <li>
                        <i class="fa fa-calendar"></i>
                        <a href="#">Monday - Friday 08:00 - 17:00</a>
                    </li>
                    <li>
                        <i class="fa fa-map-location"></i>
                        <a href="#">61 Katherine Drive,Sandton, 1st Floor - Office 321</a>
                    </li>
                    <li>
                        <i class="fa fa-phone"></i>
                        <a href="tel:0115685421">(+27) 11 568 5421</a>
                    </li>
                    <li>
                        <i class="fa fa-envelope"></i>
                        <a href="mailto:imufk@dotblack.co.za">mufk@dotblack.co.za</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>


<div class="container-fluid">

<nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="logo">
<!--            <a ><h3>dotBlack</h3></a>-->
            <img routerLink="/" style="height: 100px;    width: 200px;" src="/assets/img/dotBlackLogo.png" alt="DotBlack"/>
        </div>

        <button class="navbar-toggler" type="button" (click)="toggleClass()" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
                <li class="nav-item"><a routerLink="/" class="nav-link">Home</a></li>
                <li class="nav-item"><a routerLink="/about-us" class="nav-link">About Us</a></li>
                <li class="nav-item"><a routerLink="/products" class="nav-link">Products</a></li>
                <li class="nav-item"><a routerLink="/it-consulting" class="nav-link">IT Consulting</a></li>
                <li class="nav-item"><a routerLink="/rpa" class="nav-link">RPA</a></li>
                <li class="nav-item"><a routerLink="/project-management" class="nav-link">Project Management</a></li>
                <li class="nav-item"><a routerLink="/it-services" class="nav-link">IT Professional Services</a></li>
            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a routerLink="/contact" class="default-btn">Contact Us<span></span></a>
                    </div>
                </div>
            </div>
        </div>

</nav>
</div>
