<section class="screenshot-area" style="padding-top: 10px">
    <div class="container-fluid">
        <div class="section-title">
            <h2>Robotic Process Automation</h2>
            <div class="bar"></div>
            <h5 class="text-white">
                DotBlack in collaboration with AutomationEdge provides Chatbots,
                Intelligent Automation bots, AI and Machine Learning capabilities that help clients reduce costs,
                improve response time, grow business and achieve compliance.
            </h5>
            <h6 class="text-white">
                AutomationEdge is an Intelligent Robotic Process Automation product which comes bundled with key
                essential technologies for automation of the following:
            </h6>
        </div>

        <div class="row align-items-center">
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-sun"></i>
                    </div>
                    <br>
                    <h6>Excel Automation</h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-road-bridge"></i>
                    </div>
                    <br>
                    <h6>AutomationEdge Training</h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-chalkboard-teacher"></i>
                    </div>
                    <br>
                    <h6>Chatbot Automation</h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-compact-disc"></i>
                    </div>
                    <br>
                    <h6>IT Operations</h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>

            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-file-code"></i>
                    </div>
                    <br>
                    <h6>SAP Automation</h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-code-fork"></i>
                    </div>
                    <br>
                    <h6>Web UI Automation
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-person"></i>
                    </div>
                    <br>
                    <h6>HR Automation
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-code-pull-request"></i>
                    </div>
                    <br>
                    <h6>Front Office Automation
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-fingerprint"></i>
                    </div>
                    <br>
                    <h6>Middle Office Automation
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <div class="icon">
                        <i class="fa fa-backward"></i>
                    </div>
                    <br>
                    <h6>Back Office Automation
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="section-title">
                <h2>AutomationEdge</h2>
                <div class="bar"></div>
                <h5 class="text-white">
                    AutomationEdge is the preferred Robotic Process Automation (RPA) and IT Process automation solution
                    provider.
                    It’s highly advanced Intelligent Automation solution brings together all the essential
                    technologies required for enterprise automation like Artificial Intelligence, Machine Learning,
                    Chatbot, ETL, ready API integrations and IT Process automation.
                </h5>
                <br>
                <!--                <div class="col-lg-12">-->
                <!--                    <div class="overview-image">-->
                <!--                        <img src="assets/img/screenshot/RPA.png" alt="image" style="width: 71%">-->
                <!--                    </div>-->
                <!--                </div>-->

            </div>
        </div>
        <div class="row align-items-center">
            <div class="col">
                <div class="single-features">
                    <h6>RPA
                    </h6>
                    <div class="icon">
                        <i class="fa fa-robot"></i>
                    </div>
                    <br>
                    <h6>Robotic Process Automation
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <h6>AI
                    </h6>
                    <div class="icon">
                        <i class="fa fa-code-commit"></i>
                    </div>
                    <br>
                    <h6>Machine Learning, Chatbot and NLP
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <h6>ETL
                    </h6>
                    <div class="icon">
                        <i class="fa fa-code-branch"></i>
                    </div>
                    <br>
                    <h6>Extract, Transform, Load
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <h6>API
                    </h6>
                    <div class="icon">
                        <i class="fa fa-code"></i>
                    </div>
                    <br>
                    <h6>Rapid API Integration
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
            <div class="col">
                <div class="single-features">
                    <h6>Ready Bots
                    </h6>
                    <div class="icon">
                        <i class="fa fa-code-compare"></i>
                    </div>
                    <br>
                    <h6>Ready Bots for IT, HR & Business
                    </h6>
                    <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
                </div>
            </div>
        </div>
        <br>
        <div class="row align-items-center">
            <div class="section-title">

                <h6 class="text-white">
                    AutomationEdge has already delivered its innovative solution to large multinationals globally
                    like American Express, Capita, Coty, ICICI Lombard, HDFC Life, Smart Dubai Government, Mashreq Bank
                    and Genpact to name a few. AutomationEdge helps organizations automate their mundane, repetitive
                    rule-based tasks across verticals
                    whether it is front office, middle office or back office. AutomationEdge is listed in the Salesforce
                    AppExchange, Cherwell mApp Exchange, Splunkbase, BMC Marketplace.
                </h6>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
