<div class="main-banner-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-content">
<!--                    <h1>dotblack Makes Your Life Very Easier</h1>-->
                    <h1>dotBlack</h1>
                    <br>
                    <!--                    <p>Our passion to work hard and deliver excellent results. It could solve the needs of your customers and develop innovation.</p>-->
                    <p>We continuously invests in research and innovation by identifying factors, trends and development
                        of ideas to enable organisations
                        to maximise production and consequently revenue with limited resources.
                    </p>
                    <br>
                    <br>
                    <p>By working end-to-end across all IT dimensions from strategy, analysis and implementation, we deliver transformative outcomes.
                      </p>
<!--                    <form class="banner-form">-->
<!--                        <input type="email" class="input-newsletter" placeholder="Enter your Email">-->
<!--                        <button class="default-btn" type="submit">Get In Touch</button>-->
<!--                    </form>-->
                </div>
            </div>

        </div>
    </div>

    <div id="particles-js"></div>
</div>

<section class="about-area ptb-25">
    <div class="container">
        <div class="row py-5">
        <div class="col-lg-3 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-sun"></i>
                </div>
                <br>
                <h5>Consulting</h5>
                <p>Our approach is to analyse existing systems and new technologies to form more
                    capable...</p>
                <button class="m-2 default-btn" routerLink="about-us">Read More</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-lightbulb"></i>
                </div>
                <br>
                <h5>RPA</h5>
                <p>Provides Chatbots, Intelligent Automation bots, AI and Machine Learning
                    capabilities that help clients reduce cost...</p>
                <button class="m-2 default-btn" routerLink="/rpa">Read More</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-desktop"></i>
                </div>
                <br>
                <h5>Project Management</h5>
                <p>By using a rigorous approach, DotBlack has built a strong reputation for
                    successfully managing projects, and risk, to... </p>
                <button class="m-2 default-btn" routerLink="/project-management">Read More</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-camera"></i>
                </div>
                <br>
                <h5>IT Professional services</h5>
                <p>Finding the right technical resources at the right time can be challenging and costly. Perhaps you…</p>
                <button class="m-2 default-btn" routerLink="/it-services">Read More</button>
            </div>
        </div>
    </div>
    </div>
</section>

<!--<div class="video-area pt-100">-->
<!--    <app-how-it-works></app-how-it-works>-->
<!--</div>-->

<!--<section id="About" class="about-area ptb-100">-->
<!--    <app-about></app-about>-->

<!--    <div class="default-shape">-->
<!--        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>-->
<!--        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>-->
<!--        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>-->
<!--        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>-->
<!--        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>-->
<!--    </div>-->
<!--</section>-->

<!--<section id="ITConsulting" class="features-area pb-70">-->
<!--    <app-it-consulting></app-it-consulting>-->

<!--    <div class="default-shape">-->
<!--        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>-->
<!--        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>-->
<!--        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>-->
<!--        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>-->
<!--        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>-->
<!--    </div>-->
<!--</section>-->

<!--<section id="RPA" class="features-area pb-70">-->
<!--<app-rpa></app-rpa>-->
<!--</section>-->

<!--&lt;!&ndash;<section class="testimonials-area ptb-100">&ndash;&gt;-->
<!--&lt;!&ndash;    <app-testimonials></app-testimonials>&ndash;&gt;-->

<!--&lt;!&ndash;    <div class="default-shape">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;</section>&ndash;&gt;-->

<!--&lt;!&ndash;<section class="overview-area ptb-100">&ndash;&gt;-->
<!--&lt;!&ndash;    <app-overview></app-overview>&ndash;&gt;-->
<!--&lt;!&ndash;</section>&ndash;&gt;-->

<!--&lt;!&ndash;<section class="pricing-area pt-100 pb-70">&ndash;&gt;-->
<!--&lt;!&ndash;    <app-pricing></app-pricing>&ndash;&gt;-->

<!--&lt;!&ndash;    <div class="default-shape">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;</section>&ndash;&gt;-->

<!--<section id="ProjectManagement" class="faq-area ptb-100">-->
<!--    <app-project-management></app-project-management>-->

<!--    <div class="default-shape">-->
<!--        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>-->
<!--        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>-->
<!--        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>-->
<!--        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>-->
<!--        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>-->
<!--    </div>-->
<!--</section>-->

<!--<section  id="ITServices"  class="team-area pt-100 pb-70">-->
<!--    <app-it-services></app-it-services>-->
<!--</section>-->

<!--&lt;!&ndash;<app-app-download></app-app-download>&ndash;&gt;-->

<!--<section class="fun-facts-area pt-100 pb-70">-->
<!--    <app-funfacts></app-funfacts>-->
<!--</section>-->

<!--&lt;!&ndash;<section class="blog-area pt-100 pb-70">&ndash;&gt;-->
<!--&lt;!&ndash;    <app-blog></app-blog>&ndash;&gt;-->
<!--&lt;!&ndash;</section>&ndash;&gt;-->

<!--<section id="Contact" class="contact-area ptb-100">-->
<!--    <app-contact></app-contact>-->

<!--    <div class="default-shape">-->
<!--        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>-->
<!--        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>-->
<!--        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>-->
<!--        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>-->
<!--        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>-->
<!--    </div>-->
<!--</section>-->

