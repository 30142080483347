<section class="features-area"  style="background-color: #fafafa;">
    <div class="container">
    <div class="section-title">
        <h2>IT Consulting</h2>
        <div class="bar"></div>
        <p>Our approach is to analyse existing systems and new technologies to
            form more capable systems that are intended to take on additional tasks,
            improved performance and enhance existing systems.</p>
        <br>
        <h5>In today’s connected world, more and more systems are designed to connect,
            both within the system under construction and to systems that are already deployed.</h5>
    </div>

    <div class="row">
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-sun"></i>
                </div>
                <h3>Enterprise Applications</h3>
                <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-lightbulb"></i>
                </div>
                <h3>Business Intelligence</h3>
                <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-desktop"></i>
                </div>
                <h3>Application Integration</h3>
                <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-code"></i>
                </div>
                <h3>Software Engineering</h3>
                <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-eye"></i>
                </div>
                <h3>Infrastructure Services</h3>
                <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
            </div>
        </div>
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fa fa-camera"></i>
                </div>
                <h3>Managed services</h3>
                <!--                <p>Lorem ipsum dolor sit amet, con se ctetur adipiscing elit. In sagittis eg esta ante, sed viverra nunc tinci dunt nec elei fend et tiram.</p>-->
            </div>
        </div>
    </div>

    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                    <div class="card">
                        <div class="card-header" id="headingOne">
                            <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                Enterprise Applications
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseOne" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <ul>
                                    <li>Enterprise Resource Planning
                                        <ul>
                                            <li>Oracle</li>
                                            <li>SAP</li>
                                            <li>Microsoft</li>
                                        </ul>
                                    </li>
                                    <li>Customer Relationship Management
                                        <ul>
                                            <li>Oracle</li>
                                            <li>SAP</li>
                                            <li>Microsoft</li>
                                        </ul>
                                    </li>
                                    <li>Warehouse Management</li>
                                    <li>Financial Analysis and Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingTwo">
                            <button class="collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Business Intelligence / Analytics
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseTwo" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <ul>
                                    <li>Enterprise Data Warehouse Architecture &amp; Design</li>
                                    <li>Business Intelligence</li>
                                    <li>Industrial Internet of Things (IIOT) – Big Data Analytics</li>
                                    <li>Master Data Management</li>
                                    <li>Big Data integration</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingThree">
                            <button class="collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Application Integration
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseThree" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <ul>
                                    <li>SOA Integration Solutions</li>
                                    <li>Cloud Integration</li>
                                    <li>Identity Management / Single Sign-On</li>
                                    <li>Big Data &amp; Social Media Integration</li>
                                    <li>Industrial Internet of Things (IIOT)</li>
                                    <li>Sensory Data Integration</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">

            <div class="faq-accordion-content">
                <div class="accordion" id="faqAccordion">
                    <div class="card">
                        <div class="card-header" id="headingFour">
                            <button class="collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Software Engineering & Mobile Services
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseFour" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <ul>
                                    <li>Application Architecture</li>
                                    <li>Product Development</li>
                                    <li>Application Development</li>
                                    <li>Quality Assurance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingFive">
                            <button class="collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Infrastructure Services
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseFive" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <ul>
                                    <li>Cloud architecture, planning &amp; migration</li>
                                    <li>Infrastructure Planning &amp; Design</li>
                                    <li>IT Modernization</li>
                                    <li>Platform Migration</li>
                                    <li>Data Center Migration</li>
                                    <li>Virtualization Services</li>
                                    <li>Disaster Recovery</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header" id="headingSix">
                            <button class="collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Managed services
                                <i class="fa fa-chevron-down"></i>
                            </button>
                        </div>

                        <div id="collapseSix" class="collapse" data-bs-parent="#faqAccordion">
                            <div class="card-body">
                                <ul>
                                    <li>Infrastructure Support &amp; Maintenance</li>
                                    <li>Database Patching &amp; Performance Tuning</li>
                                    <li>Application Patching &amp; Performance Tuning</li>
                                    <li>Application Technical Support</li>
                                    <li>Application Enhancements</li>
                                    <li>End User Business Support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
