<section class="footer-area py-3" style="background-color: #000219;">
    <div class="container-fluid">
        <div class="row">
<!--            <div class="col-lg-3 col-sm-6">-->
<!--            </div>-->
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
<!--                        <img routerLink="/" style="width: 100%;" src="/assets/img/dotBlackLogo.png" alt="DotBlack"/>-->
                    </div>
<!--                    <a routerLink="/" class="logo"><h2>dotBlack</h2></a>-->
<!--                    <p>Our passion to work hard and deliver excellent results. It could solve the needs of your-->
<!--                        customers and develop innovation.</p>-->
<!--                    <ul class="social-list">-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>-->
<!--                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>-->
<!--                    </ul>-->
                </div>
            </div>

<!--            <div class="col-lg-3 col-sm-6">-->
<!--                <div class="single-footer-widget pl-5 text-white">-->
<!--                    <h3 class="text-white">Links</h3>-->

<!--                    <ul class="list text-white">-->
<!--                        <li><a routerLink="/about-us">About Us</a></li>-->
<!--                        <li><a routerLink="/products">Products</a></li>-->
<!--                        <li><a routerLink="/it-consulting">IT Consulting</a></li>-->
<!--                        <li><a routerLink="/rpa">RPA</a></li>-->
<!--                        <li><a routerLink="/project-management">Project Management</a></li>-->
<!--                        <li><a routerLink="/it-services">IT Services</a></li>-->
<!--                        <li><a routerLink="/contact">Contact Us</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-3 col-sm-6">-->
<!--                <div class="single-footer-widget">-->
<!--                    <h3 class="text-white">Address</h3>-->
<!--                    <ul class="list">-->
<!--                        <li class="location"><i class="fa fa-directions"></i> Gauteng Office | 61 Katherine Street,-->
<!--                            Sandton - 1st Floor - Office 321-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-3 col-sm-6">-->
<!--                <div class="single-footer-widget">-->
<!--                    <h3 class="text-white">Contact Details</h3>-->
<!--                    <ul class="list">-->
<!--                        <li>-->
<!--                            <i class="fa fa-envelope"></i><a href="mailto:mufk@dotblack.co.za">mufk@dotblack.co.za</a>-->
<!--                        </li>-->
<!--                        <li><i class="fa fa-phone"></i><a href="tel:(+27) 11 568 5421">(+27) 11 568 5421</a></li>-->
<!--                    </ul>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</section>

<div class="copy-right"  style="background-color: #000219;">
    <div class="container text-white">
        <div class="copy-right-content">
            <p class="text-white">© DotBlack website is Proudly Owned by <a href="https://dotblack.co.za/" target="_blank" class="text-white">DotBlack</a></p>
        </div>
    </div>
</div>
<div class="top-header">
    <div class="container">
        <div class="row align-items-center text-center">
            <div class="col-lg-12 col-sm-6">
                <ul class="header-left-content">

                    <li>
                        <i class="fa fa-calendar"></i>
                        <a href="#">Monday - Friday 08:00 - 17:00</a>
                    </li>
                    <li>
                        <i class="fa fa-map-location"></i>
                        <a href="#">61 Katherine Drive,Sandton, 1st Floor - Office 321</a>
                    </li>
                    <li>
                        <i class="fa fa-phone"></i>
                        <a href="tel:0115685421">(+27) 11 568 5421</a>
                    </li>
                    <li>
                        <i class="fa fa-envelope"></i>
                        <a href="mailto:imufk@dotblack.co.za">mufk@dotblack.co.za</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="go-top">
    <i class="fa fa-chevron-up"></i>
    <i class="fa fa-chevron-up"></i>
</div>
