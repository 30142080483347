<section class="about-area"  style="background-color: #eaeaea;">
    <div class="container-fluid">
        <div class="section-title mt-2">
            <h2>Budget Insights</h2>
            <div class="bar"></div>
<!--            <p>We understand that technology is dynamic and we have the skills, tools and methods to help you gain speed-->
<!--                and agility. By working end-to-end across all IT dimensions from strategy, analysis and implementation,-->
<!--                we deliver transformative outcomes.-->
<!--            </p>-->
        </div>



<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h4>BUDGET VARIANCE ANALYSIS.</h4>-->
<!--                    <div class="bar"></div>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h4>WHAT-IF ANALYSIS.</h4>-->
<!--                    <div class="bar"></div>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h4>MID-YEAR FORECASTING AND REPORTING.</h4>-->
<!--                    <div class="bar"></div>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h4>IMPROVED BUDGET REVIEW PROCESS.</h4>-->
<!--                    <div class="bar"></div>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                </div>-->

<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h4>RATIO ANALYSIS.</h4>-->
<!--                    <div class="bar"></div>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <br>-->
<!--        <br>-->
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12" style="text-align: justify-all; margin-bottom: 35px !important">
                <div class="about-content">
                    <h4>Take control of your budget</h4>
                    <div class="bar"></div>
                    <p>
                        <strong>Budget Insights</strong> is a custom web-based application designed to assist management to monitor the implementation of their operating income and expenditure budgets.
                        Budget variance analysis is the tool used inside Budget Insights to monitor how budgets are being implemented.
                        Large variances, both positive and negative, are an indicator that budgets are not being properly implemented and corrective action needs to be taken.
                    </p>
                    <p>
                        <strong>Budget Insights</strong>  also include a <strong>What-If analysis</strong> and <strong>scenario planning</strong> feature which allows for
                        the mid-year forecasting of the <strong>income</strong> and <strong>expenditure budgets</strong>.
                        Budget stress testing can also be carried out by using the What-If analysis feature to identify best case and/or worst-case scenarios.

                        Financial reporting, including ratio analysis, can be stream-lined to improve mid-year reporting.
                        The reporting also includes detailed variance analysis and year on year growth analysis for all income and expenditure items.
                    </p>
                    <p>
                        The budget review process can also be significantly improved by implementing
                        Budget Insights since the budget files from the application can be exported into a format that can be upload into any database or warehouse.
                    </p>
                </div>
            </div>
            <div class="col-lg-12 col-md-12" style="background-color: #eaeaea !important;" >
                <iframe height="1000rem" class="w-100"
                        src="https://app.powerbi.com/view?r=eyJrIjoiZGVkYTU3MDQtNTEzYi00NDA0LWEzYzMtZWUxMzM3YjBmNmQwIiwidCI6IjRlMmZkZDA3LWVhNGUtNDYyNy1hZWYzLTVmZTJhM2MxMTEzOSJ9&pageName=ReportSection5a87980a05938b6954d4">
                </iframe>
            </div>

<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h3>Budget Variance Analysis</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <p>Budget Insights provides detailed budget variance-->
<!--                        analysis to identify the-->
<!--                        main drivers of the variance.-->
<!--                        Through the built-in Artificial-->
<!--                        Intelligence (Ai) capabilities,-->
<!--                        Budget Insights can conduct a-->
<!--                        detailed root-cause analysis of-->
<!--                        the variance as well as identify-->
<!--                        any anomalies and provide-->
<!--                        detailed explanations for such anomalies-->
<!--                    </p>-->
<!--                    <p>The drill-down capability, using-->
<!--                        filters and slicers, also allows-->
<!--                        users to explore the budget-->
<!--                        to its lowest granularity.-->
<!--                        This helps identify the main-->
<!--                        budget items and responsible-->
<!--                        departments behind the-->
<!--                        budget variance.-->
<!--                        Conditional formatting is used-->
<!--                        to assess the impact of these-->
<!--                        variances by applying the Red,-->
<!--                        Amber and Green status.-->
<!--                    </p>-->
<!--                </div>-->

<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h3>What-If analysis, scenario planning and forecasting</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <p>Budget Insights uses What-If-->
<!--                        analysis to assess the impact-->
<!--                        on the operating surplus-->
<!--                        and change in cash flow at-->
<!--                        various scenarios (best to-->
<!--                        worst case or low to high-->
<!--                        probability scenarios). These-->
<!--                        scenarios may be based on-->
<!--                        past transactions or expected-->
<!--                        future events.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h3>Mid-year Forecasting and Reporting</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <p>What-If analysis and-->
<!--                        forecasting is used by Budget-->
<!--                        Insights to build a mid-year-->
<!--                        forecast of the budget. These-->
<!--                        forecasts include a detailed-->
<!--                        Income Statement and a-->
<!--                        Statement of Change in Cash-->
<!--                        Held (cash flow). Also, Budget-->
<!--                        Insights can be used to-->
<!--                        forecast the change in debtors-->
<!--                        and creditors based on the-->
<!--                        various levels of cash payments-->
<!--                        and receipts.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h3>Improved Budget Review Process</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <p>Most financial systems are-->
<!--                        supported by a database and/-->
<!--                        or date warehouse. Budget-->
<!--                        Insights can allow users to-->
<!--                        export budget files in a format-->
<!--                        that can be uploaded into any-->
<!--                        system. This can improve the-->
<!--                        budget review process when-->
<!--                        Budget Insights is carefully-->
<!--                        incorporated into the budget-->
<!--                        review workflow.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="col-lg-6 col-md-12">-->
<!--                <div class="about-content">-->
<!--                    <h3>Ratio Analysis</h3>-->
<!--                    <div class="bar"></div>-->
<!--                    <p>The reporting in Budget-->
<!--                        Insights also includes ratio-->
<!--                        analysis.-->
<!--                    </p>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <br>
        <br>
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-12 col-md-12">-->
<!--                <div class="section-title">-->
<!--                    <h2>Think.Future.Forward</h2>-->
<!--                    <div class="bar"></div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>
    <br>
    <br>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
