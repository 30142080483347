<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6">
            <div class="overview-content">
                <h3>Overall 400k+ Our Users Please Get Download Now</h3>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip commodo.</p>
                <div class="overview-btn">
                    <a routerLink="/" class="default-btn">Get It Now <span></span></a>
                </div>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="overview-image">
                <img src="assets/img/overview.png" alt="image">
            </div>
        </div>
    </div>
</div>