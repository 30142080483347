<div class="container">
    <div class="section-title">
        <h2>Pricing Plan</h2>
        <div class="bar"></div>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
    </div>

    <div class="tab pricing-list-tab">
        <ul class="tabs">
            <li><a href="#">Monthly</a></li>
            <li><a href="#">Yearly</a></li>
        </ul>

        <div class="tab_content">
            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Standard</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>33 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Extra features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> Upgrate options</li>
                                <li><i class="fas fa-check"></i> Full access</li>
                                <li><i class="fa fa-times"></i> Web design</li>
                                <li><i class="fa fa-times"></i> DotBlack design</li>
                                <li><i class="fa fa-times"></i> Web developement</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Personal</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>69 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Extra features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> Upgrate options</li>
                                <li><i class="fas fa-check"></i> Full access</li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fa fa-times"></i> DotBlack design</li>
                                <li><i class="fa fa-times"></i> Web developement</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Business</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>99 <sub>/ monthly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Extra features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> Upgrate options</li>
                                <li><i class="fas fa-check"></i> Full access</li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> DotBlack design</li>
                                <li><i class="fa fa-times"></i> Web developement</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tabs_item">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Standard</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>33 <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Extra features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> Upgrate options</li>
                                <li><i class="fas fa-check"></i> Full access</li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fa fa-times"></i> DotBlack design</li>
                                <li><i class="fa fa-times"></i> Web developement</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Personal</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>69 <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Extra features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> Upgrate options</li>
                                <li><i class="fas fa-check"></i> Full access</li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> DotBlack design</li>
                                <li><i class="fa fa-times"></i> Web developement</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-pricing-table">
                            <div class="pricing-header">
                                <h3>Business</h3>
                            </div>

                            <div class="price">
                                <sup>$</sup>99 <sub>/ yearly</sub>
                            </div>

                            <ul class="pricing-features">
                                <li><i class="fas fa-check"></i> Extra features</li>
                                <li><i class="fas fa-check"></i> Lifetime free support</li>
                                <li><i class="fas fa-check"></i> Upgrate options</li>
                                <li><i class="fas fa-check"></i> Full access</li>
                                <li><i class="fas fa-check"></i> Web design</li>
                                <li><i class="fas fa-check"></i> DotBlack design</li>
                                <li><i class="fas fa-check"></i> Web developement</li>
                            </ul>

                            <div class="pricing-btn">
                                <a routerLink="/" class="default-btn">Purchase <span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
