import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import {ContactComponent} from './components/common/contact/contact.component';
import {AboutComponent} from './components/common/about/about.component';
import {ItConsultingComponent} from './components/common/it-consulting/it-consulting.component';
import {RpaComponent} from './components/common/rpa/rpa.component';
import {ProjectManagementComponent} from './components/common/project-management/project-management.component';
import {ItServicesComponent} from './components/common/it-services/it-services.component';
import {SolutionsComponent} from "./components/common/solutions/solutions.component";

const routes: Routes = [
    {path: '', component: HomeTwoComponent},
    {path: 'about-us', component: AboutComponent},
    {path: 'products', component: SolutionsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'it-consulting', component: ItConsultingComponent},
    {path: 'rpa', component: RpaComponent},
    {path: 'project-management', component: ProjectManagementComponent},
    {path: 'it-services', component: ItServicesComponent},
    //
    {path: 'home-one', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'blog', component: BlogPageComponent},
    {path: 'single-blog', component: BlogDetailsComponent},
    {path: '***', redirectTo: 'HomeTwoComponent'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
