<section class="team-area pb-70"  style="background-color: #fafafa;">

    <div class="container">
        <div class="section-title">
            <h2>IT Professional Services</h2>
            <div class="bar"></div>
            <p>Finding the right technical resources at the right time can be challenging and costly.
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h6>
                        IT specialized skills</h6>
                    <h5>
                        IT Professional Services</h5>
                    <div class="bar"></div>

                </div>
                <p>
                    Perhaps you need a highly specialized IT consultant for a three-month special project or maybe you
                    wish to launch a new product—or rebuild an existing one—and need an experienced team to help you do
                    so. For any of these scenarios, the process and cost of ad placement,
                    networking, reviewing and filtering resumes, pre-qualifying and interviewing candidates, assessing
                    their technical knowledge, and checking references can be overwhelming.
                </p>
                <p>
                    Contracting or hiring the wrong person can prove very costly to your organization in the form of
                    missed deadlines, turnover, poor work quality or errors that cause problems immediately or down the
                    road. We are here to help, whether you need IT resources for two months, two years, or need them on
                    a permanent basis. Our experienced team will work diligently
                    to find the right people for your initiatives, provide you with the most highly qualified IT
                    consultants available, and help you achieve your goals faster. </p>
            </div>

        </div>
        <br>
        <br>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h5>IT Project-Based Consulting.</h5>
                    <div class="bar"></div>
                    <p>If you have current or planned technology projects—but do not have sufficient time,
                        resources or expertise to complete them—we can help through our Project-Based Consulting
                        Services. We can provide skilled resources for projects of all
                        sizes. We will work with your company to develop a detailed staffing strategy and plan to
                        complete your projects within your desired time-frame and budget.
                    </p>
                </div>

            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h5>IT Professional Placement.</h5>
                    <div class="bar"></div>
                    <p>We will help you find and hire the right candidates with the right technology skills at
                        the right time—without all of the headaches and costs of completing the recruiting process
                        in-house.
                        You will benefit from superior quality hires, better
                        retention of those hires, a faster time to hire, and a better return on investment that allows
                        you to focus on your core business.</p>

                </div>

            </div>
        </div>
        <br>
        <br>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-6">
                <div class="section-title  ">
                    <h5>Our Focus Areas</h5>
                    <div class="bar"></div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="blog-details-desc" style="font-weight: 700;">
                    <div class="article-content">
                        <ul class="features-list">
                            <li><i class="fa fa-check"></i> Agile Transformation</li>
                            <li><i class="fa fa-check"></i> Architecture</li>
                            <li><i class="fa fa-check"></i> Program/Project Management</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="blog-details-desc" style="font-weight: 700;">
                    <div class="article-content">
                        <ul class="features-list">
                            <li><i class="fa fa-check"></i> Business Intelligence</li>
                            <li><i class="fa fa-check"></i> Application Development</li>
                            <li><i class="fa fa-check"></i> Dev Ops</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="blog-details-desc" style="font-weight: 700;">
                    <div class="article-content">
                        <ul class="features-list">
                            <li><i class="fa fa-check"></i> Business Analysis</li>
                            <li><i class="fa fa-check"></i> ERP (Oracle, SAP and Microsoft)</li>
                            <li><i class="fa fa-check"></i> Database</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="blog-details-desc" style="font-weight: 700;">
                    <div class="article-content">
                        <ul class="features-list">
                            <li><i class="fa fa-check"></i> QA and Testing</li>
                            <li><i class="fa fa-check"></i> Integration</li>
                            <li><i class="fa fa-check"></i> Big Data</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
