<section id="ProjectManagement" class="faq-area"  style="background-color: #fafafa;">
    <div class="container">
        <div class="section-title">
            <h2>Programme & Project Management</h2>
            <div class="bar"></div>
            <p>
                DotBlack’s project management and advisory provide a flexible set of services to scope and scale
                according
                to needs of the project and ensure the project is delivered on-time and within budget and with the
                requisite
                quality.
            </p>

        </div>

        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="faq-accordion-content">
                    <div class="accordion" id="faqAccordion">
                        <div class="card">
                            <div class="card-header" id="headingOne">
                                <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                    By using a rigorous approach,
                                    DotBlack has built a strong reputation for successfully managing projects, and
                                    risk, to provide defined
                                    deliverables with the potential to transform businesses.
                                    <i class="fa fa-chevron-down"></i>
                                </button>
                            </div>

                            <div id="collapseOne" class="collapse show" data-bs-parent="#faqAccordion">
                                <div class="card-body">
                                    <p></p>
                                    <br>
                                    <h6> Our methodology consists of: </h6>
                                    <hr>
                                    <div class="blog-details-desc">
                                        <div class="article-content">
                                            <ul class="features-list">
                                                <li>
                                                    <i class="fa fa-check"></i>
                                                    <b>Manage Suppliers : </b>Manage the acquisition of
                                                    product/services from suppliers. Select product/service suppliers
                                                    using
                                                    Decision
                                                    Analysis and Resolution.
                                                </li>
                                                <li><i class="fa fa-check"></i><b>Manage Scope/Requirements : </b>Manage and control
                                                    project
                                                    scope, verify project requirements are baselined, placed under
                                                    change
                                                    control
                                                    and maintain requirements tractability.
                                                </li>
                                                <li><i class="fa fa-check"></i><b>Monitor and Control Project : </b>Gather team status and
                                                    metrics;
                                                    maintain stakeholder involvement, measure progress/ performance
                                                    against the
                                                    plan
                                                    to implement corrective actions. Identify and manage issues/risks.
                                                </li>
                                                <li><i class="fa fa-check"></i><b>Manage Project Resources : </b>Orient and train
                                                    team,
                                                    provide
                                                    coaching/support, and manage integrated teams.
                                                </li>
                                                <li><i class="fa fa-check"></i><b>Configuration Management : </b>Establish and
                                                    maintain the
                                                    integrity of project deliverables. Coordinate, track, and manage
                                                    change
                                                    requests.
                                                </li>
                                                <li><i class="fa fa-check"></i><b>Manage Quality : </b>Monitor and manage validation,
                                                    verification, and peer reviews.
                                                </li>
                                                <li><i class="fa fa-check"></i><b>Work Plan : </b>Consists of tasks, milestones,
                                                    schedule,
                                                    dependencies, resources, and start/end dates.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--        <div class="col-lg-6 col-md-12">-->

            <!--            <div class="faq-accordion-content">-->
            <!--                <div class="accordion" id="faqAccordion2">-->
            <!--                    <div class="card">-->
            <!--                        <div class="card-header" id="headingOne2">-->
            <!--                            <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2"-->
            <!--                                    aria-expanded="true" aria-controls="collapseOne">-->
            <!--                                Our methodology consists of:-->
            <!--                                <i class="fa fa-chevron-down"></i>-->
            <!--                            </button>-->
            <!--                        </div>-->

            <!--                        <div id="collapseOne2" class="collapse show" data-bs-parent="#faqAccordion2">-->
            <!--                            <div class="card-body">-->
            <!--                                <ul>-->
            <!--                                    <li><i class="fa fa-check"></i><b>Manage Suppliers : </b>Manage the acquisition of-->
            <!--                                        product/services from suppliers. Select product/service suppliers using Decision-->
            <!--                                        Analysis and Resolution.-->
            <!--                                    </li>-->
            <!--                                    <li><i class="fa fa-check"></i><b>Manage Scope/Requirements : </b>Manage and control project-->
            <!--                                        scope, verify project requirements are baselined, placed under change control-->
            <!--                                        and maintain requirements tractability.-->
            <!--                                    </li>-->
            <!--                                    <li><i class="fa fa-check"></i><b>Monitor and Control Project : </b>Gather team status and metrics;-->
            <!--                                        maintain stakeholder involvement, measure progress/ performance against the plan-->
            <!--                                        to implement corrective actions. Identify and manage issues/risks.-->
            <!--                                    </li>-->
            <!--                                    <li><i class="fa fa-check"></i><b>Manage Project Resources : </b>Orient and train team, provide-->
            <!--                                        coaching/support, and manage integrated teams.-->
            <!--                                    </li>-->
            <!--                                </ul>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <!--        </div>-->
        </div>
    </div>
    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
