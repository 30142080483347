<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Contact Us</h2>
            <div class="bar"></div>
            <p>DotBlack is a cross industry IT consulting and implementation company.</p>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="contact-form" style="margin-right: 50px;">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required
                                           placeholder="Your Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required
                                           placeholder="Your Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required
                                           class="form-control" placeholder="Your Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                           placeholder="Your Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6"
                                              required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button type="submit" class="default-btn">Send Message <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="contact-info">
                    <div class="contact-info-content">
<!--                        <h3>Get In Touch</h3>-->
                        <h2>
                            <i class="fa fa-phone" style="font-size: 23px"> &nbsp;&nbsp;</i>
                            <a href="tel:+27 11 568 5421">+27 11 568 5421</a>
                            <span></span>
                            <i class="fa fa-envelope" style="font-size: 23px"> &nbsp;&nbsp; </i>
                            <a href="mailto:mufk@dotblack.co.za">mufk@dotblack.co.za</a>
                            <span></span>
                            <i class="fa fa-location" style="font-size: 23px"> &nbsp;&nbsp; </i>
                            <a>
                                61 Katherine Street
                                <br>
                                Sandton
                                <br>
                                1st Floor Office 321
                            </a>
                        </h2>

<!--                        <h2>-->
<!--                            <i class="fa fa-phone" style="font-size: 23px"> &nbsp;&nbsp;</i>-->
<!--                            <a href="tel:+27 11 568 5421">+27 11 568 5421</a>-->
<!--                            <span></span>-->
<!--                            <i class="fa fa-envelope" style="font-size: 23px"> &nbsp;&nbsp; </i>-->
<!--                            <a href="mailto:mufk@dotblack.co.za">mufk@dotblack.co.za</a>-->
<!--                            -->
<!--&lt;!&ndash;                            <h5>61 Katherine Street&ndash;&gt;-->
<!--&lt;!&ndash;                            </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                            <h5>Sandton&ndash;&gt;-->
<!--&lt;!&ndash;                            </h5>&ndash;&gt;-->
<!--&lt;!&ndash;                            <h5>1st Floor Office 321&ndash;&gt;-->
<!--&lt;!&ndash;                            </h5>&ndash;&gt;-->
<!--                        </h2>-->

<!--                        <h5>61 Katherine Street-->
<!--                        </h5>-->
<!--                        <h5>Sandton-->
<!--                        </h5>-->
<!--                        <h5>1st Floor Office 321-->
<!--                        </h5>-->
<!--                        <ul class="social">-->
<!--                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>-->
<!--                        </ul>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br>
    <div class="container-fluid">
        <div class="row shadow-lg">
            <div class="col-lg-12 col-md-12">
                <iframe width="100%" height="600" frameborder="0" scrolling="no"
                        marginheight="0" marginwidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=61%20Katherine%20Street,%20Sandton+(Mupo%20Security)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">

                </iframe>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
