<section class="about-area" style="background-color: #fafafa;">
    <div class="container">
        <div class="section-title pb-5">
            <h2>About DotBlack</h2>
            <div class="bar"></div>
            <p>We understand that technology is dynamic and we have the skills, tools and methods to help you gain speed
                and agility. By working end-to-end across all IT dimensions from strategy, analysis and implementation,
                we deliver transformative outcomes.
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h4>DotBlack is a cross industry Information Technology (IT) consulting and implementation company,
                        with the main focus on:</h4>
                    <div class="bar"></div>
                    <ul>
                        <li class="">Business Analysis and strategy</li>
                        <li class="">Application and data integration services;</li>
                        <li>Data Science;</li>
                        <li>Business intelligence and advanced analytics;</li>
                        <li>Robotic Process Automation (RPA);</li>
                        <li>Software Engineering;</li>
                        <li>IT Professional Services;</li>
                    </ul>
                    <br>
                    <br>
                    <br>
                    <br>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h4>Our clients benefit from an agile, strategic approach to planning, implementing and managing
                        business and technology solutions.</h4>
                    <div class="bar"></div>
                    <p>We address solutions faced by small, medium and large establishments with regards to information
                        technology consulting, operational strategy, Business Analysis,
                        Proof of Concept, cost reduction initiatives, business case development,
                        IT training and solution blueprints as well as infrastructure development and support.
                    </p>

                    <p>We also aim to develop world-class future leaders through mentorship and employment.
                        We initiate value-added expertise to progressively integrate solutions and continue to negotiate
                        goal-oriented customer services.
                    </p>

                    <p>DotBlack is committed to providing customer-centric business solutions and services.
                        We understand that each organisation has its unique business requirements.
                        Thus, we deliver tailored business solution and services to our clients.
                    </p>
                </div>

            </div>
        </div>
        <br>
        <br>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>Our Mission</h3>
                    <div class="bar"></div>
                    <p>
                        DotBlack strives to invest in empowering resources through training & skills development that is
                        required to drive and shape our competitive economy.
                    </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <h3>Our Vision</h3>
                    <div class="bar"></div>
                    <p>DotBlack’s vision is to implement futuristic business solutions by empowering our clients with
                        practical ideas.
                    </p>
                </div>

            </div>
        </div>
        <br>
        <br>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="section-title">
                    <h2>Think.Future.Forward</h2>
                    <div class="bar"></div>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h4>Experience</h4>
                    <div class="bar"></div>
                </div>
                <div class="about-content">
                    <p>
                        <strong>Experience:</strong> DotBlack founders have invested in education while continuously gathering expertise
                        in leading business practice standards, frameworks, methods and methodologies in order to remain
                        consistent in the ever-changing business world. The founder has also had the opportunities to
                        work in world leading management consulting firms.
                        This has enabled the company to gain vast experience in management consulting across multiple
                        industries.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <h4>Research & Innovation</h4>
                    <div class="bar"></div>
                </div>
                <div class="about-content">
                    <p>
                        <strong>Research & Innovation: </strong> We understand that the business environment is dynamic and this has been
                        seen to continuously pose threats to organisations.
                        These often bring about a reduction in resources which then impacts on production. DotBlack
                        continuously invests in research
                        and innovation by identifying factors, trends and development of ideas to enable organisations
                        to maximise production and consequently revenue with limited resources.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="container-fluid">
        <div class="section-title">
<!--            <h2>Clients</h2>-->
<!--            <div class="bar"></div>-->
<!--            <p>-->
<!--            </p>-->
        </div>
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-12 col-md-12">-->
<!--                <div class="screenshot-slider owl-carousel owl-theme">-->
<!--                    <div class="screenshot-item">-->
<!--                        <div class="image">-->
<!--                            <img src="assets/img/screenshot/1.png" alt="image" style="width: 134px !important;">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="screenshot-item">-->
<!--                        <div class="image">-->
<!--                            <img src="assets/img/screenshot/2.jpeg" alt="image" style="width: 229px !important;">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="screenshot-item">-->
<!--                        <div class="image">-->
<!--                            <img src="assets/img/screenshot/3.png" alt="image" style="width: 229px !important;">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="screenshot-item">-->
<!--                        <div class="image">-->
<!--                            <img src="assets/img/screenshot/6.png" alt="image" style="width: 267px !important;">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="screenshot-item">-->
<!--                        <div class="image">-->
<!--                            <img src="assets/img/screenshot/4.png" alt="image" style="width: 134px !important;">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="screenshot-item">-->
<!--                        <div class="image">-->
<!--                            <img src="assets/img/screenshot/5.png" alt="image" style="width: 229px !important;">-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
    </div>


    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>
