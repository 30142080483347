<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</div>

<section class="single-blog-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/blog-details.jpg" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span> <a routerLink="/">September 31, 2020</a></li>
                                <li><span>Posted By:</span> <a routerLink="/">John Anderson</a></li>
                            </ul>
                        </div>

                        <h3>Weather Evident Smiling Bed Against</h3>

                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                        <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <cite>Tom Cruise</cite>
                        </blockquote>

                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/image1.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/image2.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/image3.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class="fa fa-check"></i> Scientific Skills For getting a better result</li>
                            <li><i class="fa fa-check"></i> Communication Skills to getting in touch</li>
                            <li><i class="fa fa-check"></i> A Career Overview opportunity Available</li>
                            <li><i class="fa fa-check"></i> A good Work Environment For work</li>
                        </ul>

                        <h3>Setting the mood with incense</h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                        <h3>The Rise Of Marketing And Why You Need It</h3>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class="fa fa-bookmark"></i></span>
                            <a routerLink="/">Fashion</a>,
                            <a routerLink="/">Travel</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/single-blog"><i class="flaticon-left"></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/single-blog">Next Post <i class="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">
                        <h3 class="comments-title">4 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client/1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/">April 24, 2020 at 10:59 am</a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/client/2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                    <span class="says">says:</span>
                                                </div>

                                                <div class="comment-metadata">
                                                    <a routerLink="/">April 24, 2020 at 10:59 am</a>
                                                </div>
                                            </footer>

                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>

                                            <div class="reply">
                                                <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>

                                    <ol class="children">
                                        <li class="comment">
                                            <article class="comment-body">
                                                <footer class="comment-meta">
                                                    <div class="comment-author vcard">
                                                        <img src="assets/img/client/3.jpg" class="avatar" alt="image">
                                                        <b class="fn">Sarah Taylor</b>
                                                        <span class="says">says:</span>
                                                    </div>

                                                    <div class="comment-metadata">
                                                        <a routerLink="/">April 24, 2020 at 10:59 am</a>
                                                    </div>
                                                </footer>

                                                <div class="comment-content">
                                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                                </div>

                                                <div class="reply">
                                                    <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                                </div>
                                            </article>
                                        </li>
                                    </ol>
                                </ol>
                            </li>

                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client/4.jpg" class="avatar" alt="image">
                                            <b class="fn">James Anderson</b>
                                            <span class="says">says:</span>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/">April 24, 2020 at 10:59 am</a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/single-blog" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span> Required fields are marked <span class="required">*</span></p>

                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                                </p>

                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" name="author" required="required">
                                </p>

                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" required="required">
                                </p>

                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url">
                                </p>

                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>

                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form search-top">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fa fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_apzie_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 10, 2020</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 21, 2020</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Be healthy, Enjoy life with Trifles organic</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/single-blog" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <time class="2020-06-30">June 30, 2020</time>
                                <h4 class="title usmall"><a routerLink="/single-blog">Buy organic food online and be healthy</a></h4>
                            </div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog">Business</a></li>
                            <li><a routerLink="/blog">Privacy</a></li>
                            <li><a routerLink="/blog">Technology</a></li>
                            <li><a routerLink="/blog">Tips</a></li>
                            <li><a routerLink="/blog">Uncategorized</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_comments">
                        <h3 class="widget-title">Recent Comments</h3>

                        <ul>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/single-blog">A WordPress Commenter</a>
                                </span>
                                on
                                <a routerLink="/single-blog">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/single-blog">dotblack</a>
                                </span>
                                on
                                <a routerLink="/single-blog">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/single-blog">Wordpress</a>
                                </span>
                                on
                                <a routerLink="/single-blog">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/single-blog">A WordPress Commenter</a>
                                </span>
                                on
                                <a routerLink="/single-blog">Hello world!</a>
                            </li>
                            <li>
                                <span class="comment-author-link">
                                    <a routerLink="/single-blog">dotblack</a>
                                </span>
                                on
                                <a routerLink="/single-blog">Hello world!</a>
                            </li>
                        </ul>
                    </section>

                    <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/blog">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/blog">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/blog">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/blog">10 Building Mobile Apps With Ionic And React</a></li>
                            <li><a routerLink="/blog">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/blog">Log in</a></li>
                            <li><a routerLink="/blog">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/blog">WordPress.org</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/blog">May 2020</a></li>
                            <li><a routerLink="/blog">April 2020</a></li>
                            <li><a routerLink="/blog">June 2020</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud section-bottom">
                            <a routerLink="/blog">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog">dotblack <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>

<app-subscribe></app-subscribe>
